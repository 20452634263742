<template>
    <v-card class="main-banner overflow-hidden" v-if="banner && shows">
        <swiper class="swiper" v-bind="{ options }">
            <template v-for="(slide, index) in banner?.slides">
                <swiper-slide :key="index">
                    <!-- <v-card :to="slide?.isOuterLink ? undefined : slide?.url || undefined" :href="slide?.isOuterLink ? slide?.url || undefined : undefined" :target="slide?.target" class="overflow-hidden"> -->
                    <v-card :to="slide?.isOuterLink ? undefined : slide?.url || undefined" :href="slide?.isOuterLink ? slide?.url || undefined : undefined" target="_blank" class="overflow-hidden">
                        <div class="main-banner__img" :style="`background-image:url(${slide?.image});`"></div>
                    </v-card>
                </swiper-slide>
            </template>
            <div class="swiper-control" slot="pagination">
                <div class="swiper-pagination"></div>
            </div>
        </swiper>
    </v-card>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";

const options = {
    allowTouchMove: true,
    centeredSlides: false,
    spaceBetween: 12,
    autoplay: {
        delay: 5000,
        waitForTransition: true,
        disableOnInteraction: false,
    },
    speed: 500,
    lazy: {
        loadPrevNext: true,
    },
    loop: true,
    pagination: {
        el: ".main-banner .swiper-pagination",
        clickable: true,
    },
};

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        code: { type: String, default: null },
        isPc: { type: Boolean, default: false },
        isMb: { type: Boolean, default: false },
    },
    data: () => ({
        banner: null,

        detect,
        options,
    }),
    computed: {
        shows() {
            if (this.isPc) return detect == "pc";
            if (this.isMb) return detect == "mobile";
            return true;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { code } = this;
            const { banner } = await api.v1.banners.get({ code });
            this.banner = banner;
        },
    },
};
</script>

<style lang="scss" scoped>
// main-banner
.main-banner {
    .swiper-slide {
        width: 100% !important;
    }
    &__img {
        width: 100%;
        padding-top: calc(160 / 300 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .main-banner {
        max-width: 280px;
        &__img {
            padding-top: calc(502 / 280 * 100%);
        }
    }
}
@media (min-width: 1200px) {
}
</style>
